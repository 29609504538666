(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.withdrawal-bank-account', ['pp.widgets-templates', 'pp.services.investor', 'pp.services.form', 'pp.values.glossary', 'pp.components.tooltip', 'pp.widgets.withdrawal-bank-account-iban', 'pp.widgets.duo-group-dropdown', 'pp.widgets.withdrawal-bank-account-rotw', 'pp.widgets.withdrawal-bank-account-uk', 'pp.widgets.withdrawal-bank-account-us', 'pp.components.focus-first-error', 'pp.services.core', 'pp.values.form-lists']);

var FORM_NAME = 'withdrawal.bank-account';
var UPDATED_TIMEOUT_DELAY = 5000;

/**
 * @ngdoc directive
 * @name ppWithdrawalBankAccount
 * @description
 * Allows the user to register a bank account.
 *
 * examples:
 * -iban: AL47212110090000000235698741
 * -bic/swift: AAAABBCCDDD
 *
 * @restrict A
 *
 * @todo conversion-phase-2 check account validation rules InvstorBankAccount.scala, for instance, for international accounts it is requiring both iban AND swift (should be OR)
 */

ngModule.directive('ppWithdrawalBankAccount', [function () {

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/withdrawal-bank-account/withdrawal-bank-account.tpl.html',
        scope: {
            onSubmit: '&',
            account: '<',
            hideChooseCountry: '<',
            actionLabel: '@'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', 'investorService', 'glossary', 'formLists', 'formService', 'ppTrack', function ($scope, $timeout, investorService, glossary, formLists, formService, ppTrack) {
            var vm = this;

            // -- initial state

            vm.accountDisplayNameTooltip = glossary.accountDisplayName;

            vm.formData = formLists;

            vm.accountNumberRegexp = investorService.accountNumberRegexp;
            vm.sortCodeRegexp = investorService.sortCodeRegexp;
            vm.usAccountNumberRegexp = investorService.usAccountNumberRegexp;
            vm.bicRegexp = investorService.bicRegexp;
            vm.abaRoutingCodeRegexp = investorService.abaRoutingCodeRegexp;
            vm.ibanRegexp = investorService.ibanRegexp;

            // -- util functions

            function setSubmitted(form) {
                if (form && form.$setSubmitted) {
                    form.$setSubmitted();
                    angular.forEach(form, function (item) {
                        if (item && item.$$parentForm === form && item.$setSubmitted) {
                            setSubmitted(item);
                        }
                    });
                }
            }

            function setPristine(form) {
                if (form && form.$setPristine) {
                    form.$setPristine();
                    angular.forEach(form, function (item) {
                        if (item && item.$$parentForm === form && item.$setPristine) {
                            setPristine(item);
                        }
                    });
                }
            }

            function maskFormData(error) {
                var maskedValue;
                var hiddenCharacters;

                if (!error.fieldName) {
                    return error;
                } else {
                    switch (error.fieldName) {
                    case 'accountNumber':
                        if (error.value) {
                            hiddenCharacters = error.value.length - 4;
                            maskedValue = Array(hiddenCharacters).join('.') + String(error.value).slice(hiddenCharacters, error.value.length);
                            error.value = maskedValue;
                        }
                        return error;

                    case 'sortCode':
                        if (error.value) {
                            hiddenCharacters = error.value.length - 2;
                            maskedValue = Array(hiddenCharacters).join('.') + String(error.value).slice(hiddenCharacters, error.value.length);
                            error.value = maskedValue;
                        }
                        return error;

                    default:
                        return error;
                    }
                }
            }

            function handleSuccess() {
                ppTrack.formSubmit(FORM_NAME);
                setPristine(vm.form);
                vm.updated = true;
                $timeout(function () {
                    vm.updated = false;
                }, UPDATED_TIMEOUT_DELAY);
            }

            function handleFailure(error) {
                vm.error = error.reason;
                ppTrack.formError(FORM_NAME, error.reason);
                vm.processingError = true;
            }

            function handleFinally() {
                vm.processing = false;
            }

            // -- api

            vm.submit = function ($event) {
                setSubmitted(vm.form);
                if (vm.form.$valid) {
                    ppTrack.formSubmit(FORM_NAME);
                    vm.processing = true;

                    var data = {
                        account: vm.account,
                        password: vm.password
                    };

                    vm.onSubmit(data)
                        .then(handleSuccess, handleFailure)
                        .finally(handleFinally);
                } else {
                    var errors = formService.getErrorValueList(vm.form);
                    errors = errors.map(maskFormData);
                    errors = errors.reduce(function (errorObject, error) {
                        errorObject[error.fieldName] = error.value;
                        errorObject[error.fieldName + '_' + error.type] = true;
                        return errorObject;
                    }, {});

                    ppTrack.event(FORM_NAME, errors);
                }
            };

            // -- scope bindings

            $scope.$watch('vm.account.countryCode', function (val) {
                setPristine(vm.form);
                vm.accountType = investorService.getAccountTypeByCountyCode(val);
            });

            // -- main

            vm.$onInit = function () {

                vm.account = vm.account || {
                    countryCode: 'GBR',
                    currency: 'GBP'
                };

                vm.accountType = investorService.getAccountTypeByCountyCode(vm.account.countryCode);
            };

            vm.ready = true;
        }]
    };
}]);
})(window, window.angular);